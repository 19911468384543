<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer"></script>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
  mounted() {
    this.$intercom.boot();
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.$intercom.boot({
            user_id: val.id,
            name: val.name,
            email: val.email,
          });
        }
      },
    },
    '$intercom.ready': function () {
      if(this.$intercom.ready) {
        this.$intercom.hide();
      }
    },
    "$i18n.locale": function () {
      if (Beamer) {
        Beamer.update({
          ...beamer_config,
          language: localStorage.getItem("lang") === "ru" ? "ru" : "en",
        });
      }
    },
  },
};
</script>