import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Login",
      pageTitle: "authorisation",
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/auth/Registration.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Registration",
      pageTitle: "sign_up",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPass",
    component: () => import("@/views/auth/ResetPass.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Reset Password",
      pageTitle: "change_pass",
    },
  },

  {
    path: "/recovery-password",
    name: "RecoveryPassword",
    component: () => import("@/views/auth/RecoveryPass.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Recovery Password",
      pageTitle: "",
    },
  },
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/top",
    name: "Top",
    component: () => import("@/views/Top"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/cpl-rates",
    name: "CPL Rates",
    component: () => import("@/views/RatesCPL"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("@/views/Faq"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/platform-settings/:id",
    name: "PlatformSettings",
    component: () => import("@/views/PlatformSettings"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/platforms",
    name: "Platforms",
    component: () => import("@/views/Platforms"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
    // children: [
    //   {
    //     path: "create-platform",
    //     name: "CreatePlatform",
    //     component: () => import("@/views/CreatePlatform"),
    //     meta: {
    //       auth: true,
    //       layout: "panel-layout",
    //     },
    //   },
    //   {
    //     path: "edit-platform/:id",
    //     name: "EditPlatform",
    //     component: () => import("@/views/EditPlatform"),
    //     meta: {
    //       auth: undefined,
    //       layout: "panel-layout",
    //     },
    //   },
    // ],
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("@/views/Support"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/support-chat/:id",
    name: "SupportChat",
    component: () => import("@/views/SupportChat"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/create-platform",
    name: "CreatePlatform",
    component: () => import("@/views/CreatePlatform"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/edit-platform/:id",
    name: "EditPlatform",
    component: () => import("@/views/EditPlatform"),
    meta: {
      auth: undefined,
      layout: "panel-layout",
    },
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: () => import("@/views/Statistic"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/ref-system",
    name: "RefSystem",
    component: () => import("@/views/RefSystem"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/finances",
    name: "Finances",
    component: () => import("@/views/Finances"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/api-keys",
    name: "ApiKeys",
    component: () => import("@/views/ApiKeys"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthorized = localStorage.getItem("authToken");
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (to.meta.auth === true && !isAuthorized) {
    next("/login");
  }
  if (to.path === "/auth") {
    next("/login");
  }
  if (to.meta.auth === false && isAuthorized) {
    next("/dashboard");
  }
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) {
    return next();
  }

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
});

export default router;
