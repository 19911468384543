<template>
  <aside
    :class="{ active: isOPen, mini: colapsedMenu && isDesktop }"
    class="sidebar"
  >
    <div class="sidebar__head">
      <a class="sidebar__logo" href="#">
        <img
          v-if="colapsedMenu"
          :src="require('@/assets/images/logo-mini.svg')"
          class="logo-mini"
        />
        <img v-else :src="require('@/assets/images/logo.svg')" alt="" />
      </a>
    </div>

    <div class="sidebar__content">
      <div class="sidebar__info">
        <div class="sidebar__lang language">
          <selectLanguage leftMenu />
        </div>
        <div class="sidebar__wallet">
          <div class="wallet">
            <div class="wallet__icon align-center">
              <svg v-svg role="presentation" size="0 0 20 18" symbol="usd" />
            </div>
            <div class="wallet__label">{{ $t("header.balance") }}</div>
            <div class="wallet__value">{{ numberFormat(userBalance) }} $</div>
          </div>
        </div>
      </div>
      <transition name="slide">
        <nav
          :class="{ disable: user && user.ban_reason }"
          class="sidebar__menu menu"
        >
          <ul>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/dashboard"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-1"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("dashboard.title")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/top"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-2"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("dashboard.statistic.top_countries")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/cpl-rates"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-10"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("cpl_rates.title")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/platforms"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-4"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("platforms.title")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/statistic"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-5"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("statistic.title")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/finances"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-6"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("finances.name")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/support"
                @click.native="close"
              >
                <span class="menu__icon">
                  <svg
                    fill="none"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M11.3438 2.14138L5.5398 7.91593C4.87326 7.48743 4.13488 7.15076 3.43757 6.77327C2.64796 6.35498 2.74039 5.21237 3.57101 4.91651L11.3438 2.14138ZM12.5951 3.79428L6.83189 9.52797L7.78549 11.4154C8.18542 12.2112 9.34427 12.1499 9.66216 11.3235L12.5951 3.79428ZM10.7287 0.192829C13.2103 -0.68457 15.5894 1.73329 14.6357 4.18185L11.5696 12.0683C10.6057 14.5373 7.15993 14.7005 5.96015 12.3336L4.78098 10.0279C4.68868 9.83403 4.53495 9.68094 4.35036 9.58912L2.46343 8.56889C0.115136 7.31401 0.37141 3.88607 2.88378 2.99847L10.7287 0.192829Z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("support.title")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/ref-system"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-8"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("ref_system.name")
                }}</span>
              </router-link>
            </li>
            <li
              v-if="
                featureFlags &&
                featureFlags.some((el) => el === 'openapi-for-users')
              "
            >
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/api-keys"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="api"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text"
                  >API</span
                >
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/faq"
                @click.native="close"
              >
                <div class="menu__icon">
                  <svg
                    v-svg
                    role="presentation"
                    size="0 0 18 16"
                    symbol="menu-9"
                  />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("faq.title")
                }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </transition>
      <button class="btn btn-logout" @click="logout">
        <svg
          v-svg
          role="presentation"
          class="mr-2"
          size="0 0 13 15"
          symbol="power"
        />
        {{ !colapsedMenu ? $t("logout") : "" }}
      </button>
      <div class="sidebar__manager">
        <div class="manager">
          <div class="manager__item">
            <div class="manager__icon align-center">
              <svg
                v-svg
                role="presentation"
                size="0 0 20 18"
                symbol="telegram"
              />
            </div>
            <div class="manager__label">{{ $t("header.your_manager") }}</div>
            <a class="manager__link" href="https://t.me/Leo31PH" target="_blank"
              >@Leo31PH</a
            >
          </div>
          <div class="manager__chat">
            <div class="manager__chat-label">chat</div>
            <a
              class="manager__link"
              href="https://t.me/publisher_push_house"
              target="_blank"
              >@publisher_push_house</a
            >
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
import SelectLanguage from "@/components/SelectLanguage";

export default {
  name: "MenuWrapper",
  components: { SelectLanguage },
  data: () => ({
    isDesktop: null,
  }),
  props: {
    isOPen: {},
    close: {},
    colapsedMenu: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.innerWidth >= 1200
      ? (this.isDesktop = true)
      : (this.isDesktop = false);

    window.addEventListener("resize", (e) => {
      e.target.innerWidth <= 1200
        ? (this.isDesktop = false)
        : (this.isDesktop = true);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize");
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      featureFlags: "user/getFeatureFlags",
    }),
    userBalance() {
      return this.user?.balance % 1 === 0
        ? this.user.balance?.toFixed(0)
        : this.user.balance?.toFixed(2);
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$intercom.shutdown();
      this.$intercom.boot();
      this.$router.push("/");
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>

<style lang="scss" scoped>
li a.mini {
  width: fit-content;
  padding-left: 0;
}

.btn-logout {
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 20px 0 20px 6px;
  background: #f7f7f9;
  color: #4c4c52;
  overflow: initial;
  &:hover {
    background: #dedee0 !important;
  }
}
.disable {
  pointer-events: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
</style>