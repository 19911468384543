import DashboardService from "@/api/dashboardService";
import apiService from "@/api/apiService";

const state = () => ({
  user: {
    id: null,
    email: "",
    name: "",
    avatar: "",
    balance: 0,
    telegram: "",
    skype: "",
    news: 0,
    tickets: 0,
    rid: 0,
    ref_code: "",
    ban_reason: "",
    confirm: true,
    roles: [],
    alert: null,
  },
  featureFlags: [],
});

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getFeatureFlags: (state) => {
    return state.featureFlags.length > 0
      ? state.featureFlags
      : localStorage.getItem("featureFlags")
      ? localStorage.getItem("featureFlags").split(",")
      : [];
  },
};

const mutations = {
  setUserStore(state, payload) {
    if (payload) {
      state.user = payload;
    }
  },
  setFeatureFlags(state, payload) {
    if (payload) {
      state.featureFlags = payload;
      localStorage.setItem("featureFlags", state.featureFlags);
    }
  },
};

const actions = {
  setUser({ commit }) {
    DashboardService.getUser().then((result) => {
      commit("setUserStore", result);
    });
  },
  async setFeatureFlags({ commit }) {
    await apiService.isEnabledFeatureFlag().then((result) => {
      if (result && result.status === 200)
        commit("setFeatureFlags", result.data);
      else
        commit(
          "setFeatureFlags",
          localStorage.getItem("featureFlags").split(",") || []
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
