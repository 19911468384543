import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";
import router from "./router";
import DefaultLayout from "@/layouts/DefaultLayout";
import AuthLayout from "@/layouts/AuthLayout";
import VueApexCharts from "vue-apexcharts";
import PanelLayout from "@/layouts/PanelLayout";
import VueFlags from "@growthbunker/vueflags";
import * as Sentry from "@sentry/vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VTooltip from "v-tooltip";
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";
import { helper } from "./helpers/copyCode";
import moment from "moment";
import SvgSprite from "vue-svg-sprite";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";
import VueIntercom from "vue-intercom";
import { formatNumberWithSpaces } from "./filters/formatNumbers";

Vue.use(SvgSprite, {
  url: "/images/symbols/symbol.svg",
  class: "icon-symbol",
});

Vue.filter("formatNumberWithSpaces", formatNumberWithSpaces);

Vue.prototype.$moment = moment;
Vue.prototype.$helper = helper;
Vue.use(Vuelidate);
Vue.use(VueGoodTablePlugin);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(VueIntercom, { appId: "eyauf2ue" });
Vue.use(VueFlags, {
  iconPath: "/images/flags/",
});
Sentry.init({
  Vue,
  dsn: "https://0a7df03168374961a5a6022e6e762f0b@oops2.push.house/10",
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/([a-zA-Z0-9-]+\.)?partner\.house\/api/,
      ],
    }),
  ],
});
Vue.use(Notifications);
Vue.use(VueApexCharts);
Vue.component("multiselect", Multiselect);
Vue.component("apexchart", VueApexCharts);
Vue.component("default-layout", DefaultLayout);
Vue.component("panel-layout", PanelLayout);
Vue.component("auth-layout", AuthLayout);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
